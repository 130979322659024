import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from 'react-router-dom'


const StyledDJs = styled.span`
  &:before {
    content: '${props => (props.length ? ' with ' : '')}';
  }

  text-align: center;

  @media (min-width: 576px) {
    display: block;

    &:before {
      content: '';
    }
  }
`;

const StyledLink = styled(Link)`
  color: rgba(10,10,10, 0.8);

  @media (min-width: 576px) {
    color: rgba(0,0,0, 0.8);
    
  }

  :hover {
    text-decoration: none;
    color: rgba(0,0,0,1);
  }
  

`

const formatDJs = (djs, ids) => {
  const djsCopy = djs.filter(name => name !== null && name.length > 0);
  if (djsCopy.length > 1) {
    djsCopy[djsCopy.length - 1] = `and ${djsCopy[djsCopy.length - 1]}`;
  }
  if (djsCopy.length > 2) {
    return (
      <div>
      {djs.map(
            (dj, index) => (
              <div>
                <StyledLink to={`/profile/${ids[index]}`}>
                  <b>{dj}, </b>
                </StyledLink>
              </div>
          ),
          )}
      </div>
    );
  }
  if(ids.length > 0) 
    return (
      <div>
      {djs.map(
            (dj, index) => (
              <div>
                <StyledLink to={`/profile/${ids[index]}`}>
                  <b>{dj} </b>
                </StyledLink>
              </div>
          ),
          )}
      </div>
    );
  else return null;
};

const DJs = (props) => {
  const { djs, dj_ids } = props;
  return (
    <StyledDJs length={djs.length}>
      {formatDJs(djs, dj_ids)}
    </StyledDJs>
  );
};

DJs.propTypes = {
  djs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DJs;
