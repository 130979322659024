import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Nav from './components/Nav';
import NavStreamContainer from './containers/NavStreamContainer';
import StyledContainer from './containers/StyledContainer'
import HomePage from './components/HomePage';
import ContactPage from './components/ContactPage';
import CalendarPage from './components/CalendarPage';
import BlogPage from './components/BlogPage';
import ProfileMain from './components/ProfileMain';
import ShowMain from './components/ShowMain';
import Schedule from './components/Schedule';
import Footer from './components/Footer';
import ZinePage from './components/ZinePage';
import PlaylistMain from './components/PlaylistMain';
import ListenPage from './components/ListenPage';
import RadiothonPage from './components/RadiothonPage';
import DonatePage from './components/DonatePage';
import LogsPage from './components/LogsPage';
import NewsPage from './components/NewsPage';

const App = () => (
  <Router>
    <React.Fragment>
      <Nav/>
      <NavStreamContainer />
      <StyledContainer>
        <div className='container'>
          <Route exact path="/" component={HomePage} />
          <Route path="/fm" render={() => <Schedule channel="FM" />} />
          <Route path="/digital" render={() => <Schedule channel="DIG" />} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/calendar" component={CalendarPage} />
          <Route path="/blog" component={BlogPage}/>
          <Route path="/profile/:id" component={ProfileMain} />
          <Route path="/show/:id" component={ShowMain} />
          <Route path="/playlist/:id" component={PlaylistMain} />
          <Route path="/zine" component={ZinePage} />
          <Route path="/listen" component={ListenPage} />
          <Route path="/radiothon" component={RadiothonPage} />
          <Route path="/donate" component={DonatePage} />
          <Route path="/logs" component={LogsPage} />
          <Route path="/news" component={NewsPage} />
          <Route path="/shop" component={() => { window.history.replaceState(null, "", "/"); window.location.replace('https://wmuc.umd.edu'); window.location.href = "https://wmuc.square.site"; return null;}} />
          <Route path="/wiki" component={() => {window.location.replace('https://wiki.wmuc.umd.edu');return null; }}/>
        </div>
      </StyledContainer>
      <Footer/>
    </React.Fragment>
  </Router>
);

export default App;
