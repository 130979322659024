// Action Names
const TOGGLE_THEME = 'TOGGLE_THEME'

// Colors
const DARK_GRAY = '#0f0f0f';
const LIGHT_GRAY = '#fffaee';
const CREAM = '#faf0d9';
const WMUC_DARK_GRAY =  '#0a0a0a';
const WMUC_DARK_GRAY2 = '#000000';
const WMUC_LIGHT_GRAY =  '#f7f7f7';
const WMUC_LIGHT_GRAY2 = '#ffffff';
const WMUC_RED = '#ff0000';

// Themes types
const LIGHT_THEME = {
    isLight: true,
    primary: LIGHT_GRAY,
    secondary: DARK_GRAY,
    tertiary: CREAM,
    wmucGray: WMUC_LIGHT_GRAY,
    wmucGray2: WMUC_LIGHT_GRAY2,
    wmucRed: WMUC_RED
}
// const DARK_THEME = {
//     isLight: false,
//     primary: DARK_GRAY,
//     secondary: LIGHT_GRAY,
//     wmucGray: WMUC_DARK_GRAY,
//     wmucGray2: WMUC_DARK_GRAY2
// }

const DARK_THEME = {
  isLight: true,
  primary: LIGHT_GRAY,
  secondary: DARK_GRAY,
  wmucGray: WMUC_LIGHT_GRAY,
  wmucGray2: WMUC_LIGHT_GRAY2,
  wmucRed: WMUC_RED
}

const toggleThemeHandler = state => {
  const theme = state.isLight ? 
    DARK_THEME : LIGHT_THEME;
  
  localStorage.setItem('theme', JSON.stringify(theme))
  return theme
}

const ACTION_HANDLERS = {
  [TOGGLE_THEME]: toggleThemeHandler,
};

const theme = () => {
  const theme = localStorage.getItem('theme')
  
  return theme ? JSON.parse(theme) : LIGHT_THEME
}

const reducer = (state = theme(), action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state) : state;
};

export const toggleTheme = () => ({ type: TOGGLE_THEME });
export default reducer;
