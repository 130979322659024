import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api`

const StaffContacts = styled.div`
    display: flex;
    flex-flow: row wrap;
`;

//Needs to be adjusted for dark theme
const ContactBox = styled.div`
    //This would be better if it weren't imported with every new contactcd 
    @import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Lato&display=swap'); 
    font-family: Lato;
    background: white;
    outline: 2px solid black;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    padding: 10px;
    margin: 1em 1em;
    @media (max-width: 700px) {
        margin: 0.5em 0;
    }

    display: flex;
    flex-flow: row wrap;
    flex: 350px;
`;

const StaffName = styled.h1`
    font-family: Just Another Hand;
    letter-spacing: .25rem;
    font-size: 2.5em;
    margin-bottom:0;
`
const StaffTitle = styled.p`
    margin: 0;
`
const StaffEmail = styled.p`
`

const StyledHeader = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 50px;
    font-weight: 900;
`;

const ProfilePic = styled.img`
    flex: 150px 0 0;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 0 1em 1em 0;
`

const ContactText = styled.div`
    flex: 100px 1 1;
`

const StaffContact = (props) => {

    return (
        <React.Fragment>
            <ContactBox>
                {props.image ? 
                    <ProfilePic src={props.image}></ProfilePic> :
                    <></>
                }
                <ContactText>
                    <StaffName>{props.name}</StaffName>
                    <StaffTitle>{props.title}</StaffTitle>
                    {props.email ? <StaffEmail><a href={"mailto:" + props.email}>
                        {props.email}</a></StaffEmail> : <></>}

                    {props.bio ? <p style={{fontSize: "16px", fontWeight: "400"}}>{props.bio}</p> : <p></p>}

                    {props.assistants.length > 0 ?
                        <p>Assistants: {
                            props.assistants.map((asst, i) => (
                                <span>{i ? "," : ""} {asst.name}</span>
                            ))}</p> : <></>}
                </ContactText>
            </ContactBox>
        </React.Fragment>
    )
}

const Department = (props) => {
    return (
        <div>
            {props.dept.startsWith("Exec") ?
                <></> : <h1>{props.dept}</h1>}
            <StaffContacts>
                {props.staff.map((staff) => (
                    <StaffContact
                        name={staff.name}
                        title={staff.title}
                        email={staff.email}
                        bio={staff.bio}
                        image={staff.image}
                        assistants={staff.assistants}
                    />
                ))}
            </StaffContacts>
        </div>
    );
};

const EmailContact = () => {
    return (
        <p>Please contact <a href='mailto:wmuc@umd.edu'>wmuc@umd.edu</a> for general inquiries</p>
    )
}

const ContactPage = () => {
    const [contacts, setContacts] = useState([])

    //Pasre contacts on backend
    useEffect(() => {
        const request = Axios.get(`${baseUrl}/station/staff`).then((res) => {
            setContacts(res.data)
        })
    },[])
    

    return (
        <React.Fragment>
            <StyledHeader>WMUC Staff</StyledHeader>
            <EmailContact></EmailContact>
            {Object.keys(contacts).map((dept) => (
                <Department
                    key={dept}
                    dept={dept}
                    staff={contacts[dept]}
                />
            ))}
            <StyledHeader style={{textAlign: "center"}}>Find us here!</StyledHeader>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.9613480336075!2d-76.94579457116204!3d38.983225048044844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7c695d6155555%3A0x21e23bcd8aff7b91!2sWMUC!5e0!3m2!1sen!2sus!4v1668104732474!5m2!1sen!2sus" width="600" height="450" style={{border:"4px solid black", textAlign:"center", background:"white", width: "100%"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </React.Fragment>
    )
}

export default ContactPage
