import React, { useState, useEffect } from 'react';
import Post from '../components/Post'
import StyledPostContainer from '../components/StyledPostContainer'
import styled from 'styled-components';


const StyledPostContainerNew = styled.div`

margin: auto;
padding-bot: 1rem;
justify-content: center;
width: 100%;

// If you want to make the posts the same height, uncomment bottom two lines
// display: grid;
// grid-auto-rows: 15rem;


`;

const PostsList = styled.div`
    margin: 1em 0;
`;

function BlogPageQuery(props) {
    return (
        <PostsList>
            <StyledPostContainer>
                {
                props.data.map((item) => (
                    <Post
                        key={item["url"]}
                        title={item["title"]}
                        author={item["author"]}
                        desc={item["desc"]}
                        date={item["date"]}
                        img={item["img"]}
                        url={item["url"]}
                        html={item["html"]}
                        id={item["id"]}
                    />
                ))}
            </StyledPostContainer>
        </PostsList>
    )
}

export default BlogPageQuery