import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PlaylistContainer from '../containers/PlaylistContainer';

import { Link } from 'react-router-dom'

import DOMPurify from 'dompurify';
import showService from '../services/shows'
import djService from '../services/djs'


const StyledShow = styled.div`
	background-color: ${props => props.theme.primary};
	color: ${props => props.theme.secondary}
`;

const StyledShowPic = styled.div`
  background: #fffff0;
  display: inline-block;
  // margin: 55px 75px 30px;
  padding: 15px 15px 30px;
  // height: 250px;
  width: 310px;
  height: auto;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
  text-align: center;
  color: black
  
`;

const StyledShowTab = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10%;
  margin-right: 10%;
  padding-left: 5%;
  padding-right: 5%;
  flex-direction: column;
  text-align: center;
  // background: black;

`;

const ShowMain = (props) => {
  const testShow = { "id": 245027, "start": "2022-04-08T02:00:00+0000", "end": "2022-04-08T03:00:00+0000", "duration": 3600, "timezone": "America/New_York", "one_off": false, "category": null, "title": "DIG:JoeCoolRadio", "description": "", "since": null, "url": "", "hide_dj": 0, "image": "https://farm6.staticflickr.com/5587/14756304655_007c8db746_m.jpg", "_links": { "self": { "href": "https://spinitron.com/api/shows/245027" }, "personas": [{ "href": "https://spinitron.com/api/personas/165209" }], "playlists": { "href": "/api/playlists?show_id=245027" } } }

  testShow.tag = testShow.title.split(":")[0];
  testShow.title = testShow.title.slice(testShow.title.indexOf(':') + 1);
  testShow.description = "<p>this is an example description!<p>";

  const [profile, setProfile] = useState([])
  const [djs, setDjs] = useState([])

  useEffect(() => {
    showService.getShowById(props.match.params.id).then(show => {
      if (show) {
        show.description = DOMPurify.sanitize(show.description);
        setProfile(show)
        return show.personaIds
      }
    }).then(ids => {
      if (ids) {
        ids.forEach(id => {
          djService.getDJById(id).then(dj => {
            setDjs([...djs, { "id": id, "name": dj.name }])
          })
        });
      }
    })
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // TODO: handle null images

  return (
    <StyledShow>
      <StyledShowTab>
        <StyledShowPic>
          <img src={profile.image} alt={profile.name} style={{ width: "250px", height: "250px", border: "2px black" }} />
          <h4 style={{ paddingTop: "10px" }}><b>{profile.title}</b></h4>
        </StyledShowPic>
        <div style={{ paddingTop: '15px', paddingLeft: "20px", alignSelf: "center" }}>
          <h3 style={{ textAlign: "center" }}>Description</h3>
          <div dangerouslySetInnerHTML={{ __html: profile.description }} />
        </div>
      </StyledShowTab>
      <div style={{ textAlign: "center", paddingTop: "40px" }}>
        <h3>DJs</h3>
        <>
          {
            djs.map(dj =>
              <Link key={dj.id} to={`/profile/${dj.id}`}>
                {dj.name}
              </Link>)
          }
        </>
        <h3 style={{ paddingTop: "40px" }}>Past Playlists</h3>
        <div>
        {
          profile.id ? <PlaylistContainer show_id={profile.id} /> : null
        }
        </div>
      </div>

      {/* need to implement playlists
      <div style={{ textAlign: "center", paddingTop: "40px" }}>
        <h3>Playlists</h3>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <p style={{ float: "right" }}>4/3/2022 - </p>
          <p>Example Playlist #1</p>
        </div>
      </div>
      */}
    </StyledShow>
  )
}


const mapStateToProps = (state) => {
  const { theme } = state

  return { theme }
}


export default connect(mapStateToProps)(ShowMain);