import '../index.css'
import styled from 'styled-components'

const CustomText = styled.h1`

  font-size: ${props => props.fontsize};

  transform: skew(${props => props.rotation}) rotate(${props => props.rotation});
  grid-area: text;
  font-family: 'Sarpanch', sans-serif;
  font-size: ${props => props.fontsize}vmin;
  margin: 0;
  padding: 30px;
  color: var(--wmuc-red);
  text-shadow: -.02em .02em 0 ${props => props.theme.secondary}

`;

const CustomTextShadowed = styled.h1`

  font-size: ${props => props.fontsize};

  transform: skew(${props => props.rotation}) rotate(${props => props.rotation});
  grid-area: text;
  font-family: 'Sarpanch', sans-serif;
  font-size: ${props => props.fontsize}vmin;
  margin: 0;
  padding: 30px;
  color: var(--off);
  text-shadow: 3px 3px 0 var(--wmuc-red2), -3px -3px 0 var(--color)

`;


export {CustomText, CustomTextShadowed,}
