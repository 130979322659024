import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const {
    i,
    color
  } = props;

  return (
    <i style={{color: props.color, marginLeft: "15px", width: "25px"}} className={`fa fa-${i}`} />
  );
};

Icon.propTypes = {
  i: PropTypes.string.isRequired,
};

export default Icon;
