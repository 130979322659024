import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Hour from './Hour'
import DJs from './DJs'

import { Link } from 'react-router-dom'

const StyledScheduleShow = styled.div`

  @media (min-width: 576px) {
    display: block;
    padding-bottom: ${props => props.duration * 100}%;
    opacity: ${props => (!props.isOffTheAir ? 0.9 : 1)};
    background: ${props => (!props.isOffTheAir ? 'var(--wmuc-red)' : 'none')};
    
    border-radius: 5px;
    margin-right: 1px;
    margin-left: 1px;
  }
`;

const StyledTest = styled.div`

  border-bottom: 1px solid black;
  border-top: 1px solid black;

  @media (min-width: 576px) {
    border-bottom: 0px solid black;
    border-top: 0px solid black;
  }

:nth-child(even) {
  background: #f7f7f7;

  @media (min-width: 576px) { 
    background: none;
  
  }
}

:nth-child(odd) {
  background: #e3e3e3;
  
  @media (min-width: 576px) {
    background: none;
  
  }
}



`;
const StyledLink = styled(Link)`

  @media (min-width: 576px) {
    color: rgba(234,240,246, 0.8);
  }

  :hover {
    text-decoration: none;
    color: rgba(234,240,246,1);
  }
  

`

const StyledContent = styled.div`
  padding: 0 1rem 2rem 1rem;
  display: ${props => (props.isOffTheAir ? 'none' : 'block')};
  pointer-events: ${props => (props.isOffTheAir ? 'none' : 'auto')};
  box-sizing: border-box;
  text-align: center;

  @media (min-width: 576px) {
    display: block;
    text-align: center;
    position: absolute;
    width: 100%;
    padding: 0 1px;
    // border-top: 2px solid ${props => props.theme.primary};
    box-shadow: 0px -2px 0px 0px ${props => props.theme.primary};
  }

  @media (min-width: 576px) {
    font-size: .9vw;
  }

  @media (min-width: 1200px) {
    font-size: .8rem;
  }
`;

const StyledHours = styled.span`
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  font-size: small;

  @media (min-width: 576px) {
    display: none;
  }
`;

const StyledDescription = styled.div`
  display: block;
  font-size: small;

  @media (min-width: 576px) {
    display: none;
    // overflow-y: scroll;
    // height: 100px;
  }
`;

const ScheduleShow = (props) => {
  const {
    name,
    description,
    djs,
    dj_ids,
    duration,
    lastShowPadding,
    showId,
    startHour,
    prevEndHour,
    theme
  } = props;

  const isOffTheAir = name === "Off The Air" ? true : false;
  return (
    <StyledTest>
      <StyledScheduleShow duration={startHour - prevEndHour} isOffTheAir>
        <StyledContent isOffTheAir/>
      </StyledScheduleShow>
      <StyledScheduleShow duration={duration} isOffTheAir={isOffTheAir}>
        <StyledContent theme={theme}>
          <StyledHours>
            <Hour hour={startHour} />
            -
            <Hour hour={startHour + duration} />
          </StyledHours>
          {
          showId !== 0 
          ? <StyledLink to={`/show/${showId}`} title={description}>
              {name}
            </StyledLink>
          : <p>{name}</p>
          }
          <DJs djs={djs} dj_ids={dj_ids}/>
          <StyledDescription>
            <div dangerouslySetInnerHTML={{ __html: description }}/>
          </StyledDescription>
        </StyledContent>
      </StyledScheduleShow>
      <StyledScheduleShow duration={lastShowPadding} isOffTheAir>
        <StyledContent isOffTheAir />
      </StyledScheduleShow>
    </StyledTest>
  );
};

ScheduleShow.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  djs: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.number.isRequired, 
  lastShowPadding: PropTypes.number.isRequired,
  showId: PropTypes.number.isRequired,
  startHour: PropTypes.number.isRequired,
  prevEndHour: PropTypes.number.isRequired,
};

export default ScheduleShow;
