import PropTypes from 'prop-types'

export const CHANNELS = {
  FM: {
    name: '90.5 FM',
    phone: '301-314-8800',
    url: process.env.REACT_APP_FM_ICECAST,
  },
  DIG: {
    name: 'Digital',
    phone: '301-314-6565',
    url: process.env.REACT_APP_DIG_ICECAST,
  },
};

export const NAV_LINKS = [
    {
        href: '/fm',
        label: 'Schedules',
        links: [
        ],
    },
    {
        href: "/blog",
        label: "Articles",
        links: [
            
        ],
    },
    {
        href: "/zine",
        label: "Zines",
        links: [
        ]
    },
    {
        href: "/contact",
        label: "Contact Us",
        links: [
        ]
    },
    {
        href: "/listen",
        label: "Listen",
        links: [
        ]
    },
    {
        href: "/wiki",
        label: "Wiki",
        links: [
        ]
    },
    {
        href: "/shop",
        label: "Shop",
        links: [
        ]
    },
];

export const LINK_SHAPE = PropTypes.shape({
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
}).isRequired;

export const MERIDIEMS = {
    AM: 'am',
    PM: 'pm',
};

export const DAYS = [
    'sun',
    'mon',
    'tues',
    'wed',
    'thurs',
    'fri',
    'sat',
];
