import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import gaza from '../img/gaza.png';

// Note this page onnly exitsts for the radiothon of 23 and will be removed after
const URL = "https://secure.qgiv.com/event/palestineemergency/account/1638566";
const StyledHeader1 = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 50px;
    font-weight: 900;
`;

const StyledHeaderMain = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 90px;
    font-weight: 900;
    font-family: 'Sarpanch',sans-serif;
`;

const StyledHeader2 = styled.h2`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: 900;
`;

const StyledButton = styled.a`
    font-size: 40px;
    font-family: 'Sarpanch',sans-serif;
    font-weight: 900;
    border-radius: 6px;
    padding: 1rem;
    border: 5px;
    border-color: #0f0f0f;
    border-style: solid;
    color: var(--wmuc-red);
    text-shadow: -0.03em 0.03em 0 #0f0f0f;
    :hover{
        background-color: var(--wmuc-red2);
    }
`

const StyledCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Button = () => {
    return (
        <StyledButton alt="Button to donate" type='button' href="https://secure.qgiv.com/event/palestineemergency/account/1638566">
            Donate Here!
        </StyledButton>
    );
};

const StyledResponsiveImg = styled.img`
    width: 100%;
    height: auto;
`
const RadiothonPage = () => {
    return (
        <React.Fragment>
            <StyledCenter>
                <StyledResponsiveImg src={gaza} alt="graphic for the radionthon"></StyledResponsiveImg>
                <StyledHeader2>please join us as WMUC presents</StyledHeader2>
                <StyledHeader1>24 Radionthon</StyledHeader1>
                <StyledHeader2>raising humanitarian aid for</StyledHeader2>
                <StyledHeaderMain>GAZA!</StyledHeaderMain>
                <Button></Button>
            </StyledCenter>
        </React.Fragment>
    )
}

export default RadiothonPage
