import React from 'react';
import styled from 'styled-components';
import ScaledImage from './ScaledImage';


const StyledPost = styled.div`

  text-align: left;

  background-color: white;
  margin-bottom: 1rem;
  color: black;
  // padding-bottom: 1rem;
  display: flex;
  // border-style: solid;
  // height: 190px;
  // border-radius: 10px;
  border: 1px solid black;
  // width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  @media(max-width: 760px) {
    flex-direction: column-reverse;
  }

`;

const StyledHeader = styled.h5`

  // height: 80px;
  text-align: left;
  // text-transform: uppercase;
  // float: center;

  @media(max-width: 760px) {
    text-align: center;
    align-self: center;
    float: none;
  }

`;

const PostImage = styled.div`

  float: right;
  //padding: 5px 5px 5px 0px;
  // background-color: grey;

  @media(max-width: 760px) {
    //background-color: black;
    //padding: 0px 20px;
    //border-bottom: 2px solid black;
    align-self: center;
    float: none;
  }

`;

const PostText = styled.div`

  display: flex;
  flex-direction: column;
  width: 85%;
  overflow-x: hidden;
  padding: 10px 30px 10px 10px;

  @media(max-width: 760px) {
    text-align: center;
    align-self: center;
    float: none;
  }
`;

const NoUnderlineLink = styled.a`
  :hover {
    text-decoration: none;
  }
`;


const Post = (props) => {

  const date = new Date(props.date).toDateString().split(' ')

  return (
    <React.Fragment>
      <StyledPost>
        <PostText>
          <NoUnderlineLink href={props.url} style={{ float: "left" }}>
            <StyledHeader>
              {props.title}
            </StyledHeader>
          </NoUnderlineLink>
          <p> Posted by {props.author} on {date[1] + ' ' + date[2] + ', ' + date[3]} </p> 
          <p>{props.desc} <NoUnderlineLink href={props.url}>[Read More]</NoUnderlineLink></p>
          
        </PostText>
        <PostImage>
          {props.img ? <ScaledImage img={props.img} alt="" width="190" height="100%" style={{ float: "right"}} /> : null}
        </PostImage>
      </StyledPost>
    </React.Fragment>
  )

}
    
export default Post
