
import React, { useState, useEffect } from 'react';
import spinsService from '../services/spins';
import styled from 'styled-components';
import defaultImg from '../img/digitalImg.png';

import playlistService from '../services/playlists'
import { Link } from 'react-router-dom';


const SpinsTable = styled.table`
    width: 100%;
    background-color: white;
    overflow-x:auto;
    border-radius: 10px;
    padding: 5px;


`;

const SpinsRow = styled.tr`

    :nth-child(even){
        background-color: #edd8a6;
    }

    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    text-align: left;
    // width: 90%;


`;

const InfoRow = styled.table`
margin-left: auto;
margin-right: auto;
text-align: center;`

const getPlaylistShow =  function(contents) {
    if (contents.title){
        return <h4 style={{"font-size": "170%"}}>
        Show: <Link to={"/show/" + contents.show_id}>
                    {contents.title }
                </Link>
        </h4>
    }
}

const getPlaylistTitle = function(contents){
    if (contents.episode_name){
        return <h4 style={{"font-size": "200%"}}>
        {contents.episode_name }
            </h4>
    }
}

const getPlaylistDescription = function(contents){
    if (contents.episode_description){
        return <h4 style={{"font-size": "100%"}}>
            {contents.episode_description}
        </h4>
    }
}

const getPlaylistDate = function(contents){
    if (contents.start){
    return <h4>
        Played on: {((new Date(contents.start)).toDateString())}
    </h4>
    }
}



const PlaylistMain = (props) => {

    const [spins, setSpins] = useState([])
    const [contents, setContents] = useState([]);

    useEffect(() => {
        spinsService.getSpinsByPlaylistID(props.match.params.id)
            .then(spins => {
                setSpins(
                    spins.map((spin) => ({
                        "id": spin.id,
                        "start": spin.start,
                        "image": spin.image,
                        "artist": spin.artist,
                        "released": spin.released,
                        "album": spin.release,
                        "song": spin.song,
                    }
                    )
                ))

            })
            .catch((err) => {
                console.error(err)
            });
            playlistService.getPlaylistByID(props.match.params.id)
            .then((playlists) => {
              setContents(
                playlists
                );
            })
            .catch((err) => {
              console.error(err)
            })
    }, [])

    return (
        <div>
            <InfoRow>
                    {getPlaylistTitle(contents)}
                    {getPlaylistShow(contents)}
                    {getPlaylistDate(contents)}
                    {getPlaylistDescription(contents)}
            </InfoRow>
            <div style={{overflowX: "auto"}}>
            <SpinsTable>
                <tbody>
                <tr>
                    <th></th>
                    <th>Title</th>
                    <th>Artist</th>
                    <th>Album</th>
                    <th>Released</th>
                </tr>
                {spins.map((spin) => (
                    <SpinsRow key={spin.id}>
                        <td><img src={spin.image ? spin.image : defaultImg} alt="" height="85px" width="85px" /></td>
                        <td>{spin.song}</td>
                        <td>{spin.artist}</td>
                        <td>{spin.album}</td>
                        <td>{spin.released}</td>
                    </SpinsRow>
                ))}
                </tbody>
                <tfoot>
                <tr><th style={{color: "white"}}>o</th></tr>
                </tfoot>
            </SpinsTable>
            </div>
        </div>
    )
}

export default PlaylistMain;