import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Icon from './Icon';

const StyledStreamButton = styled.div`
  display: block;
  align-items: center;
  
  &, &:hover {
    color: black;
    text-decoration: none;
  }

  & i {
    padding-left: .1rem;
  }

  @media (min-width: 576px) {
    display: inline;
  }
`;

const StreamButton = (props) => {
  const {
    icon,
    isOn,
    text,
    onClick,
    color,
  } = props;
  
  return (
    <StyledStreamButton
      isOn={isOn}
      onClick={onClick}
      color ={color}
    >
      <span style={{fontSize: "30px"}}><Icon color={color} i={icon}/></span>
      <span style={{marginTop: "10px", marginLeft: '10px'}}>{text}</span>
    </StyledStreamButton>
  );
};

StreamButton.propTypes = {
  icon: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StreamButton;
