import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PlaylistContainer from '../containers/PlaylistContainer'

import { Link } from 'react-router-dom'

import DOMPurify from 'dompurify'

import djService from '../services/djs'
import showService from '../services/shows'

const StyledProfile = styled.div`
	background-color: ${props => props.theme.primary};
	color: ${props => props.theme.secondary}
`;

const StyledProfilePic = styled.div`
  background: #fffff0;
  display: inline-block;
  // margin: 55px 75px 30px;
  padding: 15px 15px 30px;
  // height: 250px;
  width: 310px;
  height: auto;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
  text-align: center;
  color: black;
  
`;

const StyledProfileTab = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin-left: 10%;
  margin-right: 10%;
  padding-left: 5%;
  padding-right: 5%;
  // background: black;

  @media (max-width: 760px) {

    flex-direction: column;
    text-align:center;
  }
`;


const ProfileMain = (props) => {

	const testProfile = {
        "id": 2832,
        "name": "Deftly-D",
        "bio": "<p>Besides DJ'ing industrial and experimental music since 1991, Deftly has performed in industrial ... <p>",
        "since": 1991,
        "email": "example@spinitron.com",
        "website": "https://spinitron.com/example/doesnt/exist",
        "image": "https://s36537.pcdn.co/wp-content/uploads/2017/01/Pallas-cat-03-289932665.jpg.optimal.jpg",
        "_links": {
          "self": {
            "href": "string"
          },
          "shows": [
            {
              "href": "string"
            }
          ]
        }
  };

  const [profile, setProfile] = useState(testProfile)
  const [shows, setShows] = useState([])

  useEffect(()=>{
    djService.getDJById(props.match.params.id).then(dj => {
      if (dj) {
        dj.bio = DOMPurify.sanitize(dj.bio)
        setProfile(dj)
        console.log(dj.showIds)
        return dj.showIds
      }
    }).then(ids => {
      if (ids) {
        ids.forEach(id => {
          showService.getShowById(id).then(show => {
            setShows([...shows, {"id":id, "title":show.title}])
          })
        })
      }
    })
  }, [])
  useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

  return (
		<StyledProfile>
			<StyledProfileTab>
				<StyledProfilePic>
          <img src={profile.image} alt={profile.name} style={{width:"250px",height:"250px",border:"2px black"}}/>
          <h4 style={{paddingTop:"10px"}}><b>{profile.name}</b></h4>
        </StyledProfilePic>
				<div style={{paddingTop:'10px',paddingLeft:"20px",alignSelf:"start"}}>
					<h3>Bio:</h3>
					<div dangerouslySetInnerHTML={{ __html: profile.bio }}/>
				</div> 
			</StyledProfileTab>
      <div style={{textAlign:"center", paddingTop:"40px"}}>
        <h3>Shows:</h3>
        {
          shows.map(show =>
            <div key={show.id}>
              <Link key={show.id} to={`/show/${show.id}`}>
                {show.title}
              </Link>
            </div>
          )
        }
        <h3 style={{ paddingTop: "40px" }}>Past Playlists</h3>
        <div>
          {
            props.match.params.id ? <PlaylistContainer persona_id={props.match.params.id} /> : null
          }
        </div>
      </div>

		</StyledProfile>	
	)
}

const mapStateToProps = (state) => {
	const { theme } = state
	
	return { theme }
  }
  
export default connect(mapStateToProps)(ProfileMain);