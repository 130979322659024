import React, { useState, useEffect } from 'react';
import posts from '../services/posts'
import ZinePost from '../components/ZinePost'
import styled from 'styled-components';


const StyledPostContainer = styled.div`

  // padding-top: 1rem;
  display: grid;
  
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-content: flex-start;
  padding-bottom: 5rem;

  // width: 100%;
  flex-wrap: wrap;

`;


const ZinePostContainer = (props) => {
  const [contents, setContents] = useState([]);

  useEffect(() => {
    posts.getZines(props.count)
      .then((zines) => {
        setContents(
          zines.map((zine) => ({
            "title": zine.title.slice(0, 80),
            "author": zine.primary_author.name,
            "desc": zine.excerpt,
            "img": zine.feature_image,
            "url": zine.url,
            "html": zine.html,
            "id": zine.id
          }
        ))
      )}
    )
  }, [])
  return (
    <React.Fragment>
      <StyledPostContainer>
        {contents.map((info) => (
          <ZinePost
            key={info["url"]}
            title={info["title"]}
            author={info["author"]}
            desc={info["desc"]}
            img={info["img"]}
            url={info["url"]}
            html={info["html"]}
            id={info["id"]}
          />
        ))}
      </StyledPostContainer>
    </React.Fragment>
  )
}

export default ZinePostContainer
