import React, { useState } from 'react';
import defaultImg from '../img/defaultPostImage.png'
import styled from 'styled-components'

const StyledImg = styled.img`

  // for now only a featured image will have a border 

  border-top: ${props => props.border}px solid var(--color);
  border-bottom: calc(4*${props => props.border}px) solid var(--color);
  border-left: calc(2*${props => props.border}px) solid var(--color);
  border-right: calc(2*${props => props.border}px) solid var(--color);
  object-fit: cover;
`;

const ScaledImage = (props) => {
  const [height, setHeight] = useState(250)
  const [width, setWidth] = useState(250)
  //const [img, setImg] = useState('')

  let img_url = props.img;

  if (img_url === null || img_url === "") img_url = defaultImg;

  const img = new Image()
  img.addEventListener("load", function () {
    var ratio = this.naturalWidth / this.naturalHeight
    if (props.height != null && props.width != null) {
      setHeight(props.height)
      setWidth(props.width)
    } else if (props.height != null) {
      setHeight(props.height)
      setWidth(props.height * ratio)
    } else if (props.width != null) {
      setWidth(props.width)
      setHeight(props.width / ratio)
    }
  });
  img.src = img_url;

  return (
    <StyledImg 
      src={ !("img" in props) || props.img === null || props.img === "" ? defaultImg : props.img} 
      alt={props.alt} 
      height={height} 
      width={width}
      border={props.border}
    />
  )

}

export default ScaledImage
