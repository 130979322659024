import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StreamButton from './StreamButton';

const StyledStream = styled.div`
  margin-right: auto;
  font-weight: 900;
  font-size: 16px;
  font-family: Lato;
  
`;

const Stream = (props) => {
  const {
    isOn,
    text,
    onClick,
    color,
  } = props;
  return (
    <StyledStream>
      <StreamButton
        isOn={isOn}
        icon={isOn ? 'pause' : 'play'}
        text={text}
        onClick={onClick}
        color = {color}
      />
    </StyledStream>
  );
};

Stream.propTypes = {
  isOn: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Stream;
