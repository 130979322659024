import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/posts`

const getPosts = (num) => {
    const request = axios.get(`${baseUrl}/${num}`)
    return request.then(response => response.data)
}

const getPostsByPage = (count, page, search="") => {
    let params = {};
    if (search != "") {
        params.search = search;
    }

    const request = axios.get(`${baseUrl}/${count}/${page}`, {"params":params})
    return request.then(response => response.data)
}

const getNewsByPage = (count, page, search="") => {
    let params = {};
    if (search != "") {
        params.search = search;
    }

    const request = axios.get(`${baseUrl}/news/${count}/${page}`, {"params":params})
    return request.then(response => response.data)
}


const getPostById = (id) => {
    const request = axios.get(`${baseUrl}/byid/${id}`)
    return request.then(response => response.data)
}

const getFeaturedPost = () => {
    const request = axios.get(`${baseUrl}/featured`)
    return request.then(response => response.data)
}

const insertPost = (post) => {
    const request = axios.post(`${baseUrl}`, post)
    return request.then(response => response.data)
}

const getPostsWithTag = (tag) => {
    const request = axios.get(`${baseUrl}/tag/${tag}`)
    return request.then(response => response.data)
}

const getZines = (num) => {
    const request = axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/zines/${num}`)
    return request.then(response => response.data)
}

export default {getPosts, getPostsByPage, getPostById, getFeaturedPost, insertPost, getPostsWithTag, getZines, getNewsByPage}
