import styled from 'styled-components';

const StyledPostContainer = styled.div`

  // padding-top: 1rem;
  // display: grid;
  margin: auto;
  
  
  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  padding-bot: 1rem;
  justify-content: center;
  width: 100%;
`;

export default StyledPostContainer;
