import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import styled from 'styled-components';
import ScaledImage from './ScaledImage';
import wmucLogo from '../img/wmuclogo2.png'

import NavItemGroup from './NavItemGroup';


const StyledNav = styled.nav`
@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Lato&display=swap'); 
  text-transform: uppercase;
  font-weight: 1000;
  background-color: ${props => props.theme.primary};
  border-bottom: 1px solid ${props => props.theme.secondary};
  color: ${props => props.theme.secondary}
  z-index: 2;
  display: flex;
  flex-direction: row;
  // padding-bottom: 5px;
  font-size: 24px;
  
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  color: black;
  margin: 0 1rem 0 0;
  font-size: 40px;
  @media (max-width: 700px) {

    display: hidden;
    
  }
`;

const Nav = ({ theme }) => {

  return (
  <StyledNav theme={theme} className="navbar navbar-expand-lg" >
        <StyledLink style={{marginLeft: "5px", marginTop: "-5px"}} to="/">
            <img src={wmucLogo} width="130px" height="30px"></img>
        </StyledLink>
        <div style={{fontFamily: "Just Another Hand", lineHeight: "-13px", letterSpacing: "2px", marginTop: "0px", marginLeft: "40px"}}>
          <NavItemGroup theme={theme} />
        </div>
  </StyledNav>);
};

const mapStateToProps = (state) => {
  const { theme } = state
  
  return { theme }
}

export default connect(mapStateToProps)(Nav);
