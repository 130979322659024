import { connect } from 'react-redux'
import styled from 'styled-components'

const StyledContainer = styled.div`
    padding: 1rem 1rem 93px 1rem;

    // this is not a permanent solution but wanted the footer
    // to stay on the bottom even if there's no content of the page
    min-height: 100%;
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.secondary};
    width: 100%
    // overflow-x: hidden;
`;

const mapStateToProps = ({ theme }) => ({ theme })
export default connect(mapStateToProps)(StyledContainer);