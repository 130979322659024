import React, { useState, useEffect } from 'react';
import ZinePostContainer from '../containers/ZinePostContainer';
import posts from '../services/posts'
import ZinePost from './ZinePost'
import ZinePostMain from './ZinePostMain'

import styled from 'styled-components';

const StyledHeader = styled.div`
    //This would be better if it weren't imported with every new contact
    @import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Lato&display=swap'); 
    padding: 10px;
    margin-bottom: 25px;
    min-width: 250px;
    font-size: 30px;
    font-weight: 600;
    border-radius: 0px;
    font-family: Lato;


`;

const StyledText = styled.h5`

  font-weight: 400; 
  font-size: 60px;
  font-family: "Just Another Hand";
  letter-spacing: .25rem;
  // text-decoration: underline;
  // text-decoration-thickness: 3px;

  @media (max-width: 800px) {
    // margin-right: 50px;
    text-align: center;
  }
`;


const ZinePage = () => {
  const [contents, setContents] = useState([]);
  const [featuredContents, setFeaturedContents] = useState([]);

  useEffect(() => {
    posts.getZines(1)
      .then((zines) => {
        setFeaturedContents(
          zines.map((zine) => ({
            "title": zine.title.slice(0, 80),
            "author": zine.primary_author.name,
            "desc": zine.excerpt,
            "img": zine.feature_image,
            "url": zine.url,
            "html": zine.html,
            "id": zine.id
          }
          ))
        )}
    )
    posts.getZines(10)
      .then((zines) => {
        setContents(
          zines.map((zine) => ({
            "title": zine.title.slice(0, 80),
            "author": zine.primary_author.name,
            "desc": zine.excerpt,
            "img": zine.feature_image,
            "url": zine.url,
            "html": zine.html,
            "id": zine.id
          }
          ))
        )}
    )
  }, [])
  return (

    <React.Fragment>
      {featuredContents.map(zine =>
        <ZinePostMain 
          key={zine.title} 
          title={zine.title} 
          desc={zine.desc} 
          img={zine.img} 
          author={zine.author}
          url={zine.url}
        />
      )}
      <StyledHeader>
        <StyledText>Our Previous Editions</StyledText>
      </StyledHeader>
      <ZinePostContainer count="20"/>
    </React.Fragment>
  )
}

export default ZinePage