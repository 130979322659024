import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {connect} from 'react-redux'

import { DAYS } from '../constants';
import ScheduleShow from './ScheduleShow';

const StyledScheduleDayColumn = styled.div`
  padding: 0;


  @media (min-width: 576px) {
    background: ${props => props.background};
    border-left: 1px solid var(--background-color);
    border-right: 1px solid var(--background-color);
    border: 0px
  }
`;

const StyledDay = styled.div`
  background: ${props => props.background};
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  width: 100%;
  height: 2rem;
  line-height: 2rem;
  user-select: none;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  top: 2.5rem;
  padding-top: 5px;

  #sun::after,
  #mon::after,
  #tues::after,
  #thurs::after,
  #fri::after {
    content: 'day';
  }

  #wed::after {
    content: 'nesday';
  }

  #sat::after {
    content: 'urday';
  }

  @media (max-width: 576px) {
    // background: ${props => props.background};
    background: black;
    color: white;

  }

  @media (min-width: 576px) and (max-width: 992px) {
    #sun::after,
    #mon::after,
    #tues::after,
    #wed::after,
    #thurs::after,
    #fri::after,
    #sat::after {
      content: '';
    }
  }
`;

const prevEndHour = (shows, index) => {
  if (index === 0) {
    return 0;
  }
  return shows[index - 1].start_hour + shows[index - 1].duration;
};

const lastShowPadding = (shows, index) => {
  if (index === shows.length - 1) {
    return 24 - shows[index].start_hour - shows[index].duration;
  }
  return 0;
};

const ScheduleDayColumn = (props) => {
  const { index, shows, theme } = props;
  console.log(shows)
  const background = index % 2 === 0 ? theme.wmucGray : theme.wmucGray2;
  return (
    <StyledScheduleDayColumn className="col-sm" background={background}>
      <StyledDay background={background}>
        <span id={DAYS[index]}>{DAYS[index]}</span>
      </StyledDay>
      {shows.sort((x, y) => x.start_hour - y.start_hour).map((show, i) => (
        <ScheduleShow
          key={`${index}${show.start_hour}`}
          showId={show.show_id}
          name={show.name}
          djs={show.dj}
          dj_ids={show.dj_ids}
          description={show.description}
          startHour={show.start_hour}
          duration={show.duration}
          background={index % 2 === 0 ? theme.wmucGray : theme.wmucGray2}
          prevEndHour={prevEndHour(shows, i)}
          lastShowPadding={lastShowPadding(shows, i)}
          theme={theme}
        />
      ))}
    </StyledScheduleDayColumn>
  );
};

ScheduleDayColumn.propTypes = {
  index: PropTypes.number.isRequired,
  shows: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    dj: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    genres: PropTypes.string,
    start_hour: PropTypes.number,
    duration: PropTypes.number,
    show_id: PropTypes.number,
  })).isRequired,
};

const mapStateToProps = ({ theme }) => ({ theme })
export default connect(mapStateToProps)(ScheduleDayColumn);
