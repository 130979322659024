import React from "react";
import styled from 'styled-components';

const CalendarBox = styled.div`
    background: white;
    outline: 2px solid black;
    padding: 10px;
    max-width: 80%;
    text-align: center;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
`;

const StyledHeader = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 50px;
    font-weight: 900;
`;

const CalendarPage = () => {

    return (
        <div style={{ paddingTop: "1rem", margin: "auto", textAlign: "center" }}>
            <StyledHeader>
            Here is our calendar!
            </StyledHeader>
            <h3 style={{ paddingLeft: "1rem", paddingBottom: "1rem" }}>Use this to locate events you'd like to attend.</h3>


            <CalendarBox>
                <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&src=MG43Y2lkdmdsdnZnNThraDZkaGdnNW85cThAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=ZG1xNmg5bTZqNWJibzI0MGxpYjZjdGFjaWdAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=NHV2NmljMTk5aHFtZGdhZ3BlNzh0Y2pydTBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=cXE1Zzk1bTFpbzRhYTB1NTE4ZGY0cDQxbDRAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23795548&color=%23795548&color=%23F6BF26&color=%237CB342" width="100%" height="550" frameborder="0" scrolling="no"></iframe>
            </CalendarBox >
        </div>
    )

}

export default CalendarPage;