import React from 'react';
import styled from 'styled-components';

const StyledHeaderMain = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 90px;
    font-weight: 900;
    font-family: 'Sarpanch',sans-serif;
`;
const StyledButton = styled.a`
    font-size: 40px;
    font-family: 'Sarpanch',sans-serif;
    font-weight: 900;
    border-radius: 6px;
    padding: 1rem;
    border: 5px;
    border-color: #0f0f0f;
    border-style: solid;
    color: var(--wmuc-red);
    text-shadow: -0.03em 0.03em 0 #0f0f0f;
    :hover{
        background-color: var(--wmuc-red2);
    }
`

const StyledCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
`
const Button = (props) => {
    return (
        <StyledButton alt={props.alt} type='button' href={props.href}>
            {props.text}
        </StyledButton>
    );
};

const LogsPage = () => {
    return (
        <React.Fragment>
            <StyledCenter>
                <StyledHeaderMain>Do your logs!</StyledHeaderMain>
                <Button text="FM logs" alt="FM logs" href="https://forms.gle/8eTPVn3cWvvfmkS3A"></Button>
                <Button text="Digital logs" alt="Digital logs" href="https://forms.gle/8KerFXkj2YWy54Dh6"></Button>
            </StyledCenter>
        </React.Fragment>
    )
}

export default LogsPage
