import React, {useState, useEffect} from 'react';
import PostContainer from '../containers/PostContainer';

let ListenPage = () => {


  return (
    <>
      <iframe id="my-frame" src="https://spinitron.com/WMUC/?bodyclass=my-frame&css=.head.station{display:none}" width="100%" height="1200px" style={{backgroundColor: "white", marginTop: "2rem"}}></iframe>
    </>
  )
}


export default ListenPage;
