import { combineReducers } from 'redux';
import stream, * as streamActions from './stream';
import theme, * as themeActions from './theme';

export {
  streamActions,
  themeActions
};

export default combineReducers({
  stream,
  theme
});
