import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hour from './Hour';

const StyledHourLabel = styled.div`
  text-align: right;
  display: block;
  padding-bottom: 100%;
`;

const StyledContent = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  }
`;

const HourLabel = (props) => {
  const { hour } = props;
  return (
    <StyledHourLabel>
      <StyledContent>
        <Hour hour={hour} />
      </StyledContent>
    </StyledHourLabel>
  );
};

HourLabel.propTypes = {
  hour: PropTypes.number.isRequired,
};

export default HourLabel;
