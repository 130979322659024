import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/radio/playlists`

const getPlaylistByShowID = (id) => {
    const request = axios.get(`${baseUrl}?show_id=${id}`)
    return request.then(response => response.data)
}

const getPlaylistByPersonaID = (id) => {
    const request = axios.get(`${baseUrl}?persona_id=${id}`)
    return request.then(response => response.data)
}

const getPlaylistByID = (id) => {
    const request = axios.get(`${baseUrl}/${id}`)
    return request.then(response => response.data)
}

export default {getPlaylistByShowID, getPlaylistByPersonaID, getPlaylistByID}
