import React from 'react'

import ScaledImage from './ScaledImage';

import styled from 'styled-components';
import polaroidImagBg from '../img/featuredposBackground.jpg';

const StyledFeaturedPost = styled.div`

  text-align: center;
  padding: 2rem;
  display: grid;
  justify-content: center;

`;

const A = styled.a`

  position: relative;

  &:hover{
    text-decoration: none;
  }

`

const SvgDiv = styled.svg`
  @import url('https://fonts.googleapis.com/css2?family=Ultra&display=swap');
  fill: white;
  
  position: absolute;
  left: -14%;
  top: -5%;
  height: 5rem;

  font-family: 'Ultra', serif;
  font-size: 1.25rem;
  font-weight: 100;

  paint-order: stroke;
  stroke: #000000;
  stroke-width: 3px;
  stroke-linecap: butt;
  stroke-linejoin: miter;

`;

const PolaroidDiv = styled.div`
  background-image: url(${polaroidImagBg});

  width: min-content;
  height: min-content;

  padding: 1rem;

  box-shadow: 1px 0px 32px -2px rgba(0,0,0,0.57);
  -webkit-box-shadow: 1px 0px 32px -2px rgba(0,0,0,0.57);
  -moz-box-shadow: 1px 0px 32px -2px rgba(0,0,0,0.57);

  display: grid;
  grid-template-rows: auto auto;
  
`;

const TitleText = styled.text`
  @import url('https://fonts.googleapis.com/css2?family=Caveat+Brush&display=swap');
  font-family: 'Caveat Brush', cursive;
  font-size: 1.5rem;

  color: #000000;
  background-color: #f8f8f8;
  height: min-content;
  margin: 0.5rem;
`

const ImageWrapper = styled.div`
  width: 55vw;
  min-width: min-content;
  background-color: black;
`

const svgBannerSmall = (
  <SvgDiv
    viewBox="0 0 300 75"
    xmlns="<http://www.w3.org/2000/svg>"
  >
    <pathDiv>
      <path id="curve1"
        d="m 4,61 c 0,0 30,-34 90,-36 66,-3 86,41 169,-10"
      ></path>
      <path id="curve2"
        d="m 14,89 c 0,0 30,-34 90,-36 66,-3 86,41 169,-10"
      ></path>
    </pathDiv>
    <text lengthAdjust="spacing" textLength="220" fontSize="2rem" transform="translate(15,0)">
      <textPath xlinkHref="#curve1">feature of</textPath>
    </text>
    <text lengthAdjust="spacing" textLength="190" fontSize="2rem" transform="translate(15,0)">
      <textPath xlinkHref="#curve2" startOffset="30">the week</textPath>
    </text>
  </SvgDiv>
)

const svgBannerLarge = (
  <SvgDiv
    viewBox="0 0 400 75"
    xmlns="<http://www.w3.org/2000/svg>"
  >
    <pathDiv>
      <path id="curve1"
        d="m 4,61 c 0,0 30,-34 90,-36 48,3 109,23 138,29 45,9 90,5 141,-26"
      ></path>
    </pathDiv>
    <text lengthAdjust="spacing" textLength="400" fontSize="2rem" transform="translate(15,0)">
      <textPath xlinkHref="#curve1">feature of the week</textPath>
    </text>
  </SvgDiv>
)


const FeaturedPost = (props) => {
  var state = { matches: window.matchMedia("(min-width: 640px)").matches };
  return (
    <React.Fragment>
      <StyledFeaturedPost>
        <A href={props.url}>
            {state.matches ? svgBannerLarge : svgBannerSmall}
          <PolaroidDiv>
              {state.matches ? <ImageWrapper><ScaledImage img={props.img} alt="featured" height="360" /></ImageWrapper> : <ScaledImage img={props.img} alt="featured" width="330" />}
            <TitleText>{props.title}</TitleText>
          </PolaroidDiv>
        </A>
      </StyledFeaturedPost>
    </React.Fragment>
  )

}

export default FeaturedPost