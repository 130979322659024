import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { LINK_SHAPE } from '../constants';
import NavItemDropdownLink from './NavItemDropdownLink';

const StyledNavTitle = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 15px;
  color: black;

  background: ${props => props.theme.primary};

  &:hover, &:focus {
    color: var(--wmuc-red2);
    text-decoration: none;

  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.secondary};
  display: block;

  &:hover, &:focus {
    color: red;
    text-decoration: none;
  //   background-color: white;
  }
`;



const StyledDropDown = styled.div`
  text-align: center;
  background: ${props => props.theme.primary};
  border-bottom: 1px solid ${props => props.theme.secondary};
  border-left: 1px solid ${props => props.theme.secondary};
  border-right: 1px solid ${props => props.theme.secondary};
`;

class NavItem extends Component {
  constructor(props) {
    super();

    this.state = {
      isHovering: false,
    }

    this.props = props;


    this.hasLinks = props.links.length;
    this.handleMouseHover = this.handleMouseHover.bind(this);
  }

  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  render() {
    return (
      <li>
      <StyledLink to={this.props.href}>
        <StyledNavTitle
          onMouseEnter={this.handleMouseHover}
          onMouseLeave={this.handleMouseHover}
        >
          {this.props.label}
        </StyledNavTitle>
      </StyledLink>
      </li>
    );
  }
}

NavItem.propTypes = {
  label: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,

};

export default NavItem;
