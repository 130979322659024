import React from 'react';
import styled from 'styled-components';
import ScaledImage from './ScaledImage';


const StyledZinePost = styled.div`

    text-align: center;

    color: black;
    // width: 100%;
    min-width: 255px;

    padding: 20px 0px 20px 0px;
    margin: 0px 10px 20px 10px;

    border: solid grey 1px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
    border-radius: 10px;
`;

const ZinePost = (props) => {

    return (
        <React.Fragment>
            <StyledZinePost>  
                <div>
                <ScaledImage img={props.img} alt="" width="250" height="250"/>
                    <a href={props.url}> <h4 style={{paddingTop: "10px"}}>{props.title}</h4>
                    </a>
                </div>
            </StyledZinePost> 
        </React.Fragment> 
    )
}

export default ZinePost