import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api`
/*
 * The purpose of this file is to provide functions 
 * to be called by the frontend to get the current 
 * show. As the schedule is currently stored in 
 * constants.jsx, we will get it from there for now,
 * and later on we will change this file so that
 * the schedule is fetched from the database
 * once the backend is finished
 */


const getCurrShow = (channel) => {
    const request = axios.get(`${baseUrl}/currentShow/${channel}`)
    return request.then(response => {
        if (response.status === 204) {
            return null
        } else {
            return response.data
        }
    })
}

const getSchedule = (channel) => {
    const request = axios.get(`${baseUrl}/schedule/${channel}`)
    return request.then(response => {
        if (response.status === 404) {
            console.log('failed to fetch schedule')
        } else {
            return response.data
        }
    })
}

export default {getCurrShow, getSchedule}