import React from 'react'

import ScaledImage from './ScaledImage';

import styled from 'styled-components';

import { Link } from 'react-router-dom'
import { CustomText } from './CustomText';

const StyledFeaturedPost = styled.div`

  display: flex;
  position: relative;
  // text-align: center;
  justify-contents: center;
  margin-top: 40px;
  margin-bottom: 20px; 
  padding-top: 20px;
  padding-left: 1rem;
  padding-bottom: 20px;
  width: 100%;

  border: solid grey 1px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 10px;

  @media (max-width: 800px) {

    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-left: 0;

  }
  
`;

const StyledZineDesc = styled.div`

  padding-left: 1rem;
  padding-right: 1rem;

  @media (max-width: 800px;) {
    // margin-right: 50px;
    text-align: center;
  }

  // padding-top: 1rem;
  // font-size: 125%;
  // padding-botton: 1rem;

`;

const StyledDesc = styled.div`

`;

const TextDiv = styled.div`

  // position: absolute;
  // top: 10px;
  // left: 50px;

`;


const ZinePostMain = (props) => {
  return (
    <React.Fragment>
      <StyledFeaturedPost>
        <ScaledImage img={props.img} alt="featured" width="300" />
        <StyledZineDesc>
          <a href={props.url}>
            <CustomText fontsize="40px" style={{padding:0}}>{props.title}</CustomText>
          </a>
          <StyledDesc>{props.desc}</StyledDesc>
        </StyledZineDesc>
      </StyledFeaturedPost>
    </React.Fragment>
  )

}

export default ZinePostMain
