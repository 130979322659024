import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/api/radio/spins`
const pastSpinsUrl = `${process.env.REACT_APP_BACKEND_URL}/api/past_spins`

const getSpinsByPlaylistID = (id) => {
    const request = axios.get(`${baseUrl}?playlist_id=${id}`)
    return request.then(response => response.data)
}

const getPastSpins = () => {
    const request = axios.get(`${pastSpinsUrl}`)
    return request.then(response => response.data)
}

export default {getSpinsByPlaylistID, getPastSpins}
