import React, { useState, useEffect } from 'react';
import postService from '../services/posts';
import BlogPageQuery from './BlogPageQuery';
import { PostContainer } from '../containers/PostContainer';
import styled from 'styled-components';

// Copied from components/HomePage.jsx..., we should probably put all of our
// styles in one place

const HeaderText = styled.h4`
  color: red; 
  background-color: black;
  padding: 5px 0px 5px 10px;
  border-radius: 5px;
  margin-right: 5px;
  font-weight: bold;

`;

const BlogPage = () => {

    const [contents, setContents] = useState([]);
    const [inputText, setInputText] = useState("");
    const [page, setPage] = useState(1);
 // How many posts to request each time the user scrolls down
    const POST_INCREMENT = 15;
    
    useEffect(() => {
        postService.getPostsByPage(POST_INCREMENT, page, inputText)
            .then((posts) => {
                setContents(
                    posts.map((post) => ({
                        "title": post.title.slice(0, 80),
                        "author": post.primary_author.name,
                        "desc": post.excerpt,
                        "date": post.updated_at,
                        "img": post.feature_image,
                        "url": post.url,
                        "html": post.html,
                        "id": post.id
                    }))
                );
            });
    }, [page, inputText]);

    let inputHandler = (e) => {
        //convert input text to lower case
        var lowerCase = e.target.value.toLowerCase();
        setInputText(lowerCase);
    };

    const PrevPageButton = () => {
        if (page == 1) {
            return "";
        } else {
            return (
                <button
                    className="btn"
                    onClick={prevPage}
                    style={{"marginRight":"1em"}}
                >
                    Previous Page
                </button>
            );
        }
    }

    const scrollTop = () => {
        window.scrollTo(0, 0);
    }

    const prevPage = () => {
        setPage(page-1);
        scrollTop();
    }

    const nextPage = () => {
        setPage(page+1);
        scrollTop();
    }

    return (
        <div>
            <HeaderText>Our Blog</HeaderText>
            <input  className="form-control" id="blogSearcher" placeholder="Search for posts here!" onChange={inputHandler}/>
            <BlogPageQuery data={contents}/>
            <PrevPageButton/>
            <button className="btn" onClick={nextPage}>Next Page</button>
        </div>
    );

}

export default BlogPage;