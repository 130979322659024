import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${props => props.theme.secondary};
  display: block;

  &:hover, &:focus {
    color: black;
    text-decoration: none;
    background-color: white;
  }
`;

const NavItemDropdownLink = (props) => {
  const { href, label } = props;
  return (
    <></>
  );
};

NavItemDropdownLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default NavItemDropdownLink;
