import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import playlistService from '../services/playlists'
import defaultImg from '../img/digitalImg.png';

import { Link } from 'react-router-dom';



const PlaylistTable = styled.table`
    width: 100%;
    background-color: white;
    overflow-x:auto;
    border-radius: 10px;
    padding: 5px;
    text-align: left;


`;

const PlaylistRow = styled.tr`

    :nth-child(even){
        background-color: #edd8a6;
    }

    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    text-align: left;
    // width: 90%;


`;

const PlaylistContainer = (props) => {
  const [contents, setContents] = useState([]);

  var mode = "none"

  if (props.persona_id) {
    mode = "persona"
  } else if (props.show_id) {
    mode = "show"
  }

  useEffect(() => {

    if (mode == "persona") {

      playlistService.getPlaylistByPersonaID(props.persona_id)
        .then((playlists) => {
          setContents(
            playlists.map((playlist) => ({
              "id": playlist.id,
              "start": playlist.start,
              "spinsCount": playlist.spinsCount,
              "description": playlist.description,
              "episode_name": playlist.episode_name,
              "episode_description": playlist.episode_description,
              "title":  playlist.title.slice(playlist.title.indexOf(':')+1).trim(),
              "img": playlist.image,
              "url": playlist.url,     
            }
            ))
          );
        })
        .catch((err) => {
          console.error(err)
        });
    } else if (mode == "show") {

      playlistService.getPlaylistByShowID(props.show_id)
        .then((playlists) => {
          setContents(
            playlists.map((playlist) => ({
              "id": playlist.id,
              "start": Date.parse(playlist.start),
              "spinsCount": playlist.spinsCount,
              "description": playlist.description,
              "episode_name": playlist.episode_name,
              "episode_description": playlist.episode_description,
              "title":  playlist.title.slice(playlist.title.indexOf(':')+1).trim(),
              "img": playlist.image,
              "url": playlist.url,     
            }
            ))
          );
        })
        .catch((err) => {
          console.error(err)
        });
    } else {
      console.log("must specify show_id or persona_id for playlist container")
    }

  }, [])

  return (
    <div style={{ overflowX: "auto" }}>
      <PlaylistTable>
        <tbody>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Date</th>
            <th>Songs</th>
          </tr>
          {contents.map((info) => (
            <PlaylistRow key={info.id}>
              <td style={{ width: "95px" }}>
                <Link to={"/playlist/" + info.id}>
                  <img src={info.img ? info.img : defaultImg} alt="" height="85px" width="85px" />
                </Link>
              </td>
              <td>
                <Link to={"/playlist/" + info.id}>
                  {info.episode_name ? info.episode_name : info.title}
                </Link>
              </td>
              <td>
                {((new Date(info.start)).toDateString().split(' '))[1] + ' '}
                {((new Date(info.start)).toDateString().split(' '))[2] + ', '}
                {((new Date(info.start)).toDateString().split(' '))[3]}
              </td>
              <td>{info.spinsCount}</td>
            </PlaylistRow>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th style={{ color: "white" }}>o</th>
          </tr>
        </tfoot>

      </PlaylistTable>
    </div>
  )
}

export default PlaylistContainer
