import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CHANNELS, DAYS } from '../constants';
import HourLabel from './HourLabel';
import ScheduleDayColumn from './ScheduleDayColumn';

import scheduleService from '../services/schedule'

import { Link } from 'react-router-dom'


const StyledSchedule = styled.div`
  padding-top: 1rem;

  & > :first-child {
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
  }

  & > :first-child > :first-child {
    height: 2rem;
    line-height: 2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  
`;

const StyledLeft = styled.div`
  position: relative;
  text-align: center;
  color: ${props => props.color};
  display: ${props => (props.hide ? 'none' : 'block')};

  @media (min-width: 576px) {
    text-align: right;
    display: block;
  }
`;

const Switcher = styled.div`
  text-align: center;
  // background-color: white;

`;

const StyledLink = styled(Link)`
  width: 100%;
`;

const Schedule = (props) => {
  const { channel } = props;
  const [schedule, setSchedule] = useState([[],[],[],[],[],[],[]])
  useEffect(()=>{
    scheduleService.getSchedule(channel).then(result => {
      if (result) {
        setSchedule(result)
      }
    })
  }, [])
  return (
    <React.Fragment>
      <Switcher>
        {channel == 'FM' ? <StyledLink to='/digital'>View Digital Schedule Here </StyledLink> :
            <StyledLink to='/fm'>View FM Schedule Here </StyledLink>
        }
      </Switcher>
      <StyledSchedule className="row">
        <div className="col-sm">
          <StyledLeft color="var(--wmuc-red)">
            {CHANNELS[channel].name}
          </StyledLeft>
          {
            [...Array(24).keys()].map(i => (
              <StyledLeft hide key={i}>
                <HourLabel hour={i} />
              </StyledLeft>
            ))
          }
        </div>
        {schedule.map(
          (day, index) => (
            <ScheduleDayColumn
              key={DAYS[index]}
              index={index}
              shows={day}
            />
          ),
        )}
      </StyledSchedule>
    </React.Fragment>
  );
};

Schedule.propTypes = {
  channel: PropTypes.string.isRequired,
};

export default Schedule;
