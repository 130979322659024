import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import gaza from '../img/gaza.png';

// Note this page onnly exitsts for the radiothon of 23 and will be removed after
const URL = "https://giving.umd.edu/giving/Fund.php?name=wmuc-support-fund";
const StyledHeader1 = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 50px;
    font-weight: 900;
`;

const StyledHeaderMain = styled.h1`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 90px;
    font-weight: 900;
    font-family: 'Sarpanch',sans-serif;
`;

const StyledHeader2 = styled.h2`
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: 900;
`;

const StyledButton = styled.a`
    font-size: 40px;
    font-family: 'Sarpanch',sans-serif;
    font-weight: 900;
    padding: 1rem;
    color: var(--wmuc-red);
    text-shadow: -0.03em 0.03em 0 #0f0f0f;
    :hover{
        text-decoration: none;
        text-shadow: -0.05em 0.05em 0 #0f0f0f;
    }
`

const StyledCenter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const Button = () => {
    return (
        <StyledButton alt="Button to donate" type='button' href={URL}>
            Donate Here!
        </StyledButton>
    );
};


const EmailContact = () => {
    return (
        <p>Please contact <a href='mailto:wmuc@umd.edu'>wmuc@umd.edu</a> for general inquiries</p>
    )
}

const StyledResponsiveImg = styled.img`
    width: 100%;
    height: auto;
`
const RadiothonPage = () => {
    return (
        <React.Fragment>
            <StyledCenter>
                <StyledHeader1>Please donate here.</StyledHeader1>
                <StyledHeader2>We appreciate you for supporting college radio!</StyledHeader2>
                <Button></Button>
                <EmailContact></EmailContact>
            </StyledCenter>
        </React.Fragment>
    )
}

export default RadiothonPage
