import { connect } from 'react-redux';
import NavStreamExpanded from '../components/NavStreamExpanded'

import { streamActions } from '../ducks';

const mapStateToProps = state => ({
  isFmOn: state.stream.isFmOn,
  isDigitalOn: state.stream.isDigitalOn,
  url: state.stream.url,
  theme: state.theme
});

const mapDispatchToProps = dispatch => ({
  toggleStreamFm: () => dispatch(streamActions.toggleStreamFm()),
  toggleStreamDigital: () => dispatch(streamActions.toggleStreamDigital()),
});

// export default connect(mapStateToProps, mapDispatchToProps)(NavStream);
export default connect(mapStateToProps, mapDispatchToProps)(NavStreamExpanded);
