import React, { useState, useEffect } from 'react';
import postService from '../services/posts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Post from '../components/Post'
import FeaturedPost from '../components/FeaturedPost'
import StyledPostContainer from '../components/StyledPostContainer'
import styled from 'styled-components';

const PageChanger = styled.div`

  background-color: white;
  margin-top: 2rem;
  text-align: center;
  position: relative;
  left: 5%;
  // width: 20%;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: baseline;
  border-radius: 5px;
  border: 2px solid black;
  align-self: flex-end;

  float: bottom;

  @media (max-width: 420px) {
    // flex-direction: column;
    // width: 85%;
    // left: 15%;
    text-align: center;
    
  }

`;

const newButton = styled.button`

  :focus {outline:0;}

`;

const HeaderText = styled.h4`
  color: red; 
  background-color: black;
  padding: 5px;
  // position: relative;
  float: top;
  // left: 15%;
  margin-left: 31%; 
  width: 69%;
  padding-left: 10px;
  border-radius: 5px;
  // align-self: center; 

  @media(max-width: 1000px) {
    margin-left: 0%; 
    width: 100%;
  }

`;


const FeaturedPostContainer = (props) => {
  const [featuredContents, setFeaturedContents] = useState([]);

  useEffect(() => {
    postService.getFeaturedPost()
      .then((posts) => {
        setFeaturedContents(
          posts.map((post) => ({
            "title": post.title.slice(0, 80),
            "author": post.primary_author.name,
            "desc": post.excerpt,
            "img": post.feature_image,
            "url": post.url,
            "html": post.html,
            "id": post.id
          }
          ))
        );
      })
      .catch((err) => {
        console.error(err)
      });
  }, []);

  return (
    <React.Fragment>
      {featuredContents.map((info) => (
        <FeaturedPost
          key={info["url"]}
          title={info["title"]}
          author={info["author"]}
          desc={info["desc"]}
          img={info["img"]}
          url={info["url"]}
          html={info["html"]}
          id={info["id"]}
        />
      ))}
    </React.Fragment>
  )

}

const PostContainer = (props) => {
  const [contents, setContents] = useState([]);
  const [page, setPage] = useState(1)

  
  let changePage = (type) => {

    // this is a placeholder until we know how many max pages we have
    const lastPage = 89;

    let isMounted = true;
    let localPage = page;

    switch(type) {
      case 'first':
        localPage = 1;
        break;
      case 'minus5':
        localPage -= 5;
        break;
      case 'prev':
        localPage -= 1;
        break;
      case 'next':
        localPage += 1;
        break;
      case 'plus5':
        localPage += 5;
        break;
      case 'last':
        localPage = lastPage;
        break;
      default:
        return;
    }

    if (localPage < 1)
      localPage = 1;
    else if (localPage > lastPage)
      localPage = lastPage;

    setPage(localPage);

    postService.getPostsByPage(props.count, localPage)
      .then((posts) => {
        if (isMounted) setContents(
          posts.map((post) => ({
            "title": post.title.slice(0, 80),
            "author": post.primary_author.name,
            "desc": post.excerpt,
            "img": post.feature_image,
            "url": post.url,
            "html": post.html,
            "date": post.updated_at,
            "id": post.id
          }
          ))
        );
      })
      .catch((err) => {
        console.error(err)
      });
    window.scrollTo(0, 600);
    return () => {isMounted = false}
  }


  useEffect(() => {
    let isMounted = true;
    postService.getPostsByPage(props.count, 1)
      .then((posts) => {
        if (isMounted) setContents(
          posts.map((post) => ({
            "title": post.title.slice(0, 80),
            "author": post.primary_author.name,
            "desc": post.excerpt,
            "img": post.feature_image,
            "url": post.url,
            "html": post.html,
            "date": post.updated_at,
            "id": post.id
          }
          ))
        );
      })
      .catch((err) => {
        console.error(err)
      });
      return () => {isMounted = false}
  }, [])
  return (
    <React.Fragment>
      <HeaderText><b>Read Our Media</b></HeaderText>
      <StyledPostContainer>
        {contents.map((info) => (
          <Post 
              key={info["url"]}
            title={info["title"]}
            author={info["author"]}
            desc={info["desc"]}
            img={info["img"]}
            url={info["url"]}
            html={info["html"]}
            id={info["id"]}
            date={info["date"]}
          />
        ))}
      </StyledPostContainer>
      <PageChanger>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
        <newButton onClick={event => changePage('first')} className="btn"><i className="fa fa-step-backward" aria-hidden="true"></i></newButton>
        <newButton onClick={event => changePage('minus5')} className="btn"><b>-5</b></newButton>
        <newButton onClick={event => changePage('prev')} className="btn"><i className="fa fa-arrow-left" aria-hidden="true"></i></newButton>
        <b style={{paddingLeft: "10px", paddingRight: "10px"}}>Pg {page}</b>
        <newButton onClick={event => changePage('next')} className="btn"><i className="fa fa-arrow-right" aria-hidden="true"></i></newButton>
        <newButton onClick={event => changePage('plus5')} className="btn"><b>+5</b></newButton>
        <newButton onClick={event => changePage('last')} className="btn"><i className="fa fa-step-forward" aria-hidden="true"></i></newButton>
      </PageChanger>
    </React.Fragment>
  )
}

export {FeaturedPostContainer, PostContainer};
