import { CHANNELS } from '../constants';

const TOGGLE_STREAM_FM = 'TOGGLE_STREAM_FM';
const TOGGLE_STREAM_DIGITAL = 'TOGGLE_STREAM_DIGITAL';

const toggleStreamFmHandler = state => ({
  isFmOn: !state.isFmOn,
  isDigitalOn: false,
  url: CHANNELS.FM.url,
});

const toggleStreamDigitalHandler = state => ({
  isFmOn: false,
  isDigitalOn: !state.isDigitalOn,
  url: CHANNELS.DIG.url,
});

const ACTION_HANDLERS = {
  [TOGGLE_STREAM_FM]: toggleStreamFmHandler,
  [TOGGLE_STREAM_DIGITAL]: toggleStreamDigitalHandler,
};

const reducer = (state = {
  isFmOn: false,
  isDigitalOn: false,
  url: '',
}, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state) : state;
};

export const toggleStreamFm = () => ({ type: TOGGLE_STREAM_FM });
export const toggleStreamDigital = () => ({ type: TOGGLE_STREAM_DIGITAL });
export default reducer;
