import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import Stream from './Stream';
import ScaledImage from './ScaledImage';

import radioImg from '../img/wmucicon.png'
import digitalImg from '../img/wmucicon2.png'
import wmucLogo from '../img/wmuclogo.png'

import scheduleService from '../services/schedule'
import djService from '../services/djs'

import { Link } from 'react-router-dom'





const StyledNavStreamExpanded = styled.nav`
    width: 100%;
    
    display: flex;
    flex-direction: row;
    background: blue;
    flex-wrap: wrap;    
`;




const StyledBroadcastDigital = styled.div`
  background: white;
  flex: 1;
  min-width: 300px;
  max-height: 200px;
  flex-grow: 2;
  color: #151515;
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #151515;
  border-top: 1px solid #151515;
  transition: background-color 0.2s ease-in-out;
    -webkit-transition: color .2s
    -o-transition: color .2s;
    -moz-transition: color .2s;
  transition: color 0.2s ease-in-out;
    -webkit-transition: background-color .2s;
    -o-transition: background-color .2s;
    -moz-transition: background-color .2s;
  
`;

const StyledBroadcastThumbnail = styled.div`
    width: 200px;
    height:200px;
    display: flex;
    flex-shrink: 0;
    color: white;
`;

const StyledBroadcastThumbnailNumber = styled.div`
    position: absolute;
    top:0;
    left:0;
`;

const StyledBroadcastThumbnailPlay = styled.div`
    position: relative;
    bottom:0;
    left:0;

`;


const StyledBroadcastContents = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 10px;
    overflow-y: auto;
    max-height: 200px;
`;

const StyledBroadcastTime = styled.div`
   font-weight: 600;
`;

const StyledBroadcastTitle = styled.div`
   font-weight:800;
   font-size: 30px;
   line-height: 25px;
   
`;

const StyledBroadcastHosts = styled.div`
   font-size: 18px;
   margin-top: 5px;
`;

const StyledBroadcastDesc = styled.div`
   font-size: 14px;
   overflow-y: auto;
   max-height: 80px;
`;






const LiveText = styled.div`
    padding-left: 40px;
    padding-right: 40px;
    font-family: Londrina Outline;
    letter-spacing: 4px;
  
    font-size: 28px;
    font-weight: 1000;
    
`;

const StyledNavStreamShowInfo = styled.div`
    
    display: flex;
    width: 100%;
    min-width: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Lato;
    font-size: 15px;
    justify-content: space-between;
`;


const NumberText = styled.div`
    display: flex;
    line-height: 100%;
    font-family: Lato;
    height: 100%;
    font-size: 25px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 900;
    color: white;
    background-color: #151515;
    line-height: -20px;
`;

const NumberTextStream = styled.div`
    font-family: Lato;
    font-size: 23px;
    text-align: center;
    background-color: #151515;
    height: 40px;
    width: 40px;
    color: white;
    font-weight: 900;

`;

const defaultFMInfo = {
  "title": "Off The Air",
  "dj": [],
  "image": "url(" + radioImg  + ")",
  "personaIds": []
}

const defaultDigitalInfo = {
  "title": "Off The Air",
  "dj": [],
  "image": "url(" + digitalImg  + ")",
  "personaIds": []
}

const StyledNavStream = styled.nav`
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Londrina+Outline&display=swap');
  display:flex;
  flex-display: row;
  height: 47px;
  max-height: 47px;
  overflow: hidden;
  white-space: nowrap;
  overflow: ellipsis;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: ${props => props.theme.primary};
  font-size: small;
  border-bottom: 2px solid ${props => props.theme.secondary};
  border-top: 2px solid #151515;
  border-right: 2px solid #151515;
  position: fixed;
  color: ${props => props.theme.secondary};
  right: 0;
  top: 0;
  left: 0;
  z-index: 10;
  transition: top 0.7s ease;
  padding: 0;
`;

const StyledLink = styled(Link)`

  text-decoration: none;
  // color: white;

  &:hover, &:focus {
    text-decoration: none;
  //   background-color: white;
  }
`;


const NavStreamExpanded = (props) => {
  const {
    isFmOn,
    isDigitalOn,
    toggleStreamFm,
    toggleStreamDigital,
    url,
    theme
  } = props;

  const COLOR_HIGHLIGHT = "#151515"

  const [width, setWidth] = useState(window.innerWidth);
  const [FMStreamTheme, setFMStreamTheme] = useState({ color: COLOR_HIGHLIGHT, backgroundColor: "white" })
  const [digitalStreamTheme, setDigitalStreamTheme] = useState({ color: COLOR_HIGHLIGHT, backgroundColor: "white" })

  useEffect(() => {
    if (isDigitalOn == true) {
      setDigitalStreamTheme({ color: "white", backgroundColor: COLOR_HIGHLIGHT })
      setFMStreamTheme({ color: COLOR_HIGHLIGHT, backgroundColor: "white" })
    } else
      if (isFmOn == true) {
        setDigitalStreamTheme({ color: COLOR_HIGHLIGHT, backgroundColor: "white" })
      }
  }, [isDigitalOn])

  useEffect(() => {
    if (isFmOn == true) {
      setFMStreamTheme({ color: "white", backgroundColor: COLOR_HIGHLIGHT })
      setDigitalStreamTheme({ color: COLOR_HIGHLIGHT, backgroundColor: "white" })
    } else
      if (isDigitalOn == true) {
        setFMStreamTheme({ color: COLOR_HIGHLIGHT, backgroundColor: "white" })
      }
  }, [isFmOn])

  useEffect(() => {
    setFMStreamTheme({ color: "white", backgroundColor: COLOR_HIGHLIGHT })
  }, [])

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });


  const [FMInfo, setFMInfo] = useState(defaultFMInfo)
  const [digitalInfo, setDigitalInfo] = useState(defaultDigitalInfo)
  const [visible, setVisible] = useState(true);
  const styledNavStreamRef = useRef()

  //possibly make it so this doesn't update so often
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const height = styledNavStreamRef.current.offsetHeight

    // setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 395) || currentScrollPos < 395);
    setVisible(currentScrollPos < height + 100)
    // setPrevScrollPos(currentScrollPos);
  };


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);

  }, []); // to make it so it only update once, just leave the braces empty


  const fetchChannelInfo = (channel, setter, info) => {
    scheduleService.getCurrShow(channel).then(show => {
      if (!show) {
        setter(info)
        return
      }

      if (show.image && show.title != "Off The Air") {
        if (!show.image.includes("data:image")) {
          show.image = "url(" + show.image + ")"
        }
      } else show.image = "url(" + (channel == 'FM' ? radioImg :  digitalImg ) + ")"

      show.dj = []
      if (show.personaIds.length === 0) {
        setter(show)
        return
      }
      show.personaIds.forEach(async (id) => {
        const dj = await djService.getDJById(id)
        if (dj.name.length !== 0) {
          show.dj.push(dj.name)
        }
        let startTime = new Date()
        startTime = startTime.getHours()
        let endTime = new Date()
        endTime = endTime.getHours()
        endTime = (endTime + (show.duration / 3600)) % 24
        startTime = startTime + ":00"
        endTime = endTime + ":00"

        show.times = startTime + "-" + endTime
        show.description = show.description_stripped
        setter(show)
      })
    })
  }

  useEffect(() => {
    fetchChannelInfo('FM', setFMInfo, defaultFMInfo)
  }, [])

  useEffect(() => {
  }, [FMInfo.image])

  useEffect(() => {
    fetchChannelInfo('DIG', setDigitalInfo, defaultDigitalInfo)
  }, [])

  useEffect(() => {
  }, [FMInfo])

  useEffect(() => {
  }, [digitalInfo])




  return (
    <>
      <StyledNavStreamExpanded
        style={{ top: `${visible ? 0 : '-300px'}` }}
        ref={styledNavStreamRef}
        theme={theme}
      >
        <StyledBroadcastDigital style={{ color: FMStreamTheme.color, backgroundColor: FMStreamTheme.backgroundColor }}>
          <div style={{ backgroundImage: FMInfo.image, backgroundSize: "100% 100%", boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)", marginTop: "-1px", marginBottom: "-1px" }}>
            <StyledBroadcastThumbnail>
              <div style={{ maxWidth: "40px", display: "flex", flexDirection: "column", alignContent: "space-between", justifyContent: "space-between" }}>
                <NumberTextStream>1</NumberTextStream>
                <div style={{ marginBottom: "10px" }}>
                  <Stream
                    color="white"
                    isOn={isFmOn == null ? false : isFmOn}
                    onClick={toggleStreamFm}
                  />
                </div>
              </div>
              <div style={{ fontWeight: 900, marginLeft: "10px", fontSize: "25px", fontFamily: "Lato", letterSpacing: "1px" }}>90.5FM</div>
            </StyledBroadcastThumbnail>
          </div>
          <StyledBroadcastContents>
            <StyledBroadcastTime>{FMInfo.times}</StyledBroadcastTime>
            <StyledLink to={!(FMInfo.title === "Off The Air") ? '/show/' + FMInfo.id : '/fm'} style={{ color: FMStreamTheme.color }}>
              <StyledBroadcastTitle>{FMInfo["title"]}</StyledBroadcastTitle>
            </StyledLink>
            <StyledLink to={!(FMInfo.title === "Off The Air") ? '/profile/' + FMInfo.personaIds[0] : '/fm'} style={{ color: FMStreamTheme.color }}>
              <StyledBroadcastHosts>{FMInfo["dj"].length ? FMInfo["dj"].join(" and ") : ""}</StyledBroadcastHosts>
            </StyledLink>
            <StyledBroadcastDesc>{FMInfo.description}
            </StyledBroadcastDesc>
          </StyledBroadcastContents>
        </StyledBroadcastDigital>
        <StyledBroadcastDigital style={{ color: digitalStreamTheme.color, backgroundColor: digitalStreamTheme.backgroundColor }}>
          <div style={{ backgroundImage: digitalInfo.image, backgroundSize: "100% 100%", boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.6)", marginTop: "-1px", marginBottom: "-1px" }}>
            <StyledBroadcastThumbnail>
              <div style={{ maxWidth: "40px", display: "flex", flexDirection: "column", alignContent: "space-between", justifyContent: "space-between" }}>
                <NumberTextStream><span>2</span></NumberTextStream>
                <div style={{ marginBottom: "10px" }}>
                  <Stream
                    color="white"
                    isOn={isDigitalOn == null ? false : isDigitalOn}
                    onClick={toggleStreamDigital}
                  />
                </div>
              </div>
              <div style={{ fontWeight: 900, marginLeft: "10px", fontSize: "25px", fontFamily: "Lato", letterSpacing: "1px" }}>DIGITAL</div>

            </StyledBroadcastThumbnail>
          </div>
          <StyledBroadcastContents>
            <StyledBroadcastTime>{digitalInfo.times}</StyledBroadcastTime>
            <StyledLink to={!(digitalInfo.title === "Off The Air") ? '/show/' + digitalInfo.id : '/digital'} style={{ color: digitalStreamTheme.color }}>
              <StyledBroadcastTitle>
                {digitalInfo["title"]}
              </StyledBroadcastTitle>
            </StyledLink>
            <StyledLink to={!(digitalInfo.title === "Off The Air") ? '/profile/' + digitalInfo.personaIds[0] : '/digital'} style={{ color: digitalStreamTheme.color }}>
              <StyledBroadcastHosts>
                {digitalInfo["dj"].length ? 
                  <div>{digitalInfo["dj"].join(" ")}</div> : ""}
              </StyledBroadcastHosts>
            </StyledLink>
            <StyledBroadcastDesc>
            </StyledBroadcastDesc>
          </StyledBroadcastContents>
        </StyledBroadcastDigital>
        <ReactPlayer url={url} playing={isDigitalOn || isFmOn} width="0" height="0" volume={1} />
      </StyledNavStreamExpanded>

      <StyledNavStream

        style={{ background: 'white', top: `${visible ? '-300px' : '0'}` }}
        theme={theme}
      >
        {width > 700 ?
          <LiveText>LIVE</LiveText>
          :
          <div></div>
        }
        {width > 500 ?
          <NumberText><span style={{ marginTop: "7px" }}>1</span></NumberText>
          : <div></div>
        }
        <Stream
          isOn={isFmOn}
          onClick={toggleStreamFm}
        />
        <StyledNavStreamShowInfo style={{}}>
          <div style={{ maxWidth: "50%", fontWeight: "1000" }}>{FMInfo["title"]}</div>
          {width > 900 ?
            <div style={{ minWidth: "0", fontWeight: "100", marginRight: "10px" }}>{FMInfo["dj"].length ? FMInfo["dj"].join(", ") : ""}</div>
            :
            <div></div>
          }
        </StyledNavStreamShowInfo>
        {width > 500 ?
          <NumberText><span style={{ marginTop: "7px" }}>2</span></NumberText>
          :
          <div></div>
        }
        <Stream
          isOn={isDigitalOn}
          onClick={toggleStreamDigital}
        />
        <StyledNavStreamShowInfo>
          <div style={{ maxWidth: "50%", fontWeight: "1000" }}>{digitalInfo["title"]}</div>
          {width > 900 ?
            <div style={{ marginRight: "10px", maxWidth: "50%" }}>{digitalInfo["dj"].length ? digitalInfo["dj"].join(", ") : ""}</div> :
            <div></div>
          }
        </StyledNavStreamShowInfo>


      </StyledNavStream>
    </>
  )
};

NavStreamExpanded.propTypes = {
  isFmOn: PropTypes.bool.isRequired,
  isDigitalOn: PropTypes.bool.isRequired,
  toggleStreamFm: PropTypes.func.isRequired,
  toggleStreamDigital: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default NavStreamExpanded;
