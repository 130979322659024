import PropTypes from 'prop-types';
import { MERIDIEMS } from '../constants';

const Hour = (props) => {
  const { hour } = props;
  let hour12 = hour;
  let meridiem = MERIDIEMS.AM;

  if (hour12 > 12) {
    hour12 -= 12;
    if (hour12 !== 12) {
      meridiem = MERIDIEMS.PM;
    }
  } else if (hour12 === 12) {
    meridiem = MERIDIEMS.PM;
  } else if (hour12 === 0) {
    hour12 = 12;
  }

  const hours = Math.floor(hour12);
  const minutes = hour12 - hours;
  if (minutes > 0) {
    hour12 = `${hours}:${Math.floor(minutes * 60)}`;
  }

  return hour12 + meridiem;
};

Hour.propTypes = {
  hour: PropTypes.number.isRequired,
};

export default Hour;
